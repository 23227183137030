<template>
  <div>
    <div
      class="mask-wrapper"
      v-show="showSeason"
      @click.stop="showSeason = false"
    ></div>
    <el-popover
      placement="bottom"
      popper-class="quarter-popover-custom"
      trigger="manual"
      v-model="showSeason"
    >
      <div class="season-picker-box">
        <div class="el-date-picker__header el-date-picker__header--bordered">
          <i
            aria-label="前一年"
            class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left"
            @click="prev"
          />
          <span role="button" class="el-date-picker__header-label"
            >{{ year }}年</span
          >
          <i
            aria-label="后一年"
            @click="next"
            class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"
          />
        </div>

        <div class="el-picker-panel__content">
          <table class="el-month-table">
            <tr>
              <td :class="{ today: current == 1 && quarterValue.split('-')[0] == year, disable: year<startYear || year>endYear || (year==startYear && 1<startOptions) || (year==endYear && 1>endOptions)}">
                <div>
                  <a class="cell" v-if="year<startYear || year>endYear || (year==startYear && 1<startOptions) || (year==endYear && 1>endOptions)">一季度</a>
                  <a class="cell" v-else @click="selectSeason(1)">一季度</a>
                </div>
              </td>
              <td :class="{ today: current == 2 && quarterValue.split('-')[0] == year, disable: year<startYear || year>endYear || (year==startYear && 2<startOptions) || (year==endYear && 2>endOptions)}">
                <div>
                  <a class="cell" v-if="year<startYear || year>endYear || (year==startYear && 2<startOptions) || (year==endYear && 2>endOptions)">二季度</a>
                  <a class="cell" v-else @click="selectSeason(2)">二季度</a>
                </div>
              </td>
            </tr>
            <tr>
              <td :class="{ today: current == 3 && quarterValue.split('-')[0] == year, disable: year<startYear || year>endYear || (year==startYear && 3<startOptions) || (year==endYear && 3>endOptions)}">
                <div>
                  <a class="cell" v-if="year<startYear || year>endYear || (year==startYear && 3<startOptions) || (year==endYear && 3>endOptions)">三季度</a>
                  <a class="cell" v-else @click="selectSeason(3)">三季度</a>
                </div>
              </td>
              <td :class="{ today: current == 4 && quarterValue.split('-')[0] == year, disable: year<startYear || year>endYear || (year==startYear && 4<startOptions) || (year==endYear && 4>endOptions)}">
                <div>
                  <a class="cell" v-if="year<startYear || year>endYear || (year==startYear && 4<startOptions) || (year==endYear && 4>endOptions)">四季度</a>
                  <a class="cell" v-else @click="selectSeason(4)">四季度</a>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <el-input
        slot="reference"
        placeholder="请选择季度"
        :value="strValue"
        size="small"
        prefix-icon="el-icon-date"
        @focus="showSeason = true"
      />
    </el-popover>
  </div>
</template>

<script>
export default {
  model: {
    prop: "quarterValue",
    event: "quarterChange",
  },
  props: {
    quarterValue: {
      require: true,
      type: String,
    },
    pickerOptions: {
      require: true,
      type: Array,
    }
  },

  data() {
    return {
      showSeason: false,
      year: new Date().getFullYear(),
      strValue: "",
      current: "",
      startYear: "",
      startOptions: "",
      endYear: "",
      endOptions: "",
    };
  },
  watch: {
    quarterValue: {
      handler(val, old) {
        this.strValue = `${val.split('-')[0]}年${val.split('-')[1]}季度`;
      },
      immediate: true
    },
    showSeason(val) {
      if (val) {
        this.current = this.quarterValue
          ? Number(this.quarterValue.split("-")[1])
          : "";
      }
    },
    pickerOptions: {
      handler(arr, old) {
        let startTime = arr[0].split('-')
        let endTime = arr[1].split('-')
        this.startYear = startTime[0]
        this.startOptions = startTime[1]
        this.endYear = endTime[0]
        this.endOptions = endTime[1]
      },
      immediate: true
    }
  },
  methods: {
    prev() {
      this.year = this.year * 1 - 1;
    },

    next() {
      this.year = this.year * 1 + 1;
    },

    selectSeason(i) {
      this.season = `${this.year}-${i}`;
      this.$emit("quarterChange", this.season);
      this.showSeason = false;
      const cnNum = ["一", "二", "三", "四"];
      this.strValue = `${this.year}年${cnNum[i - 1]}季度`;
    },
  },
};
</script>

<style scoped lang="scss">
.quarter-popover-custom {
  padding: 0;
  width: 3rem !important;
}
.mask-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
}

.season-picker-box {
  margin-bottom: 0;
  border-bottom: 1px solid #ebeef5;
  .el-month-table {
    width: 100%;
  }
  .el-month-table td .cell {
    width: 80px;
  }
  .el-month-table td.disable .cell {
    background-color: #F5F7FA;
    cursor: not-allowed;
    color: #A1A4AF;
  }
}

.box-card {
  width: 240px;
  padding: 0 3px;
  margin-top: 10px;
  position: fixed;
  z-index: 9999;

  .item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-button {
      width: 80px;
      margin: 5px;
      color: #606266;
      &:hover {
        color: #45a3fc;
      }
    }
  }
}
</style>
