<template>
  <div>
    <div class="top_model">
      <div class="left flex">
        <span
          class="ellipsis"
          style="max-width: 900px;font-weight:600;height:66px; line-height: 66px;margin-right: 90px; font-size: 22px;font-family: FZLanTingHei-DB-GBK;color: #333333;"
          >{{ companyName }}</span
        >
        <div class="store_le flex">
          <div><img src="" alt="" /></div>
          <div style="font-weight:600">门店:</div>
          <div>
            <span>{{ this.storeData.storeCount }}</span>
            <span>个</span>
          </div>
        </div>
        <div class="poople_le flex">
          <div><img src="" alt="" /></div>
          <div style="font-weight:600">员工:</div>
          <div>
            <span>{{ this.storeData.staffCount }}</span>
            <span>个</span>
          </div>
        </div>
      </div>
    </div>
    <div class="top-card">
      <div class="title">回收数据环比分析</div>
      <div class="btn-box">
        <el-button
          :type="dateType == '01' ? 'primary' : 'default'"
          @click="selectTime('01')"
          >按月份</el-button
        >
        <el-button
          :type="dateType == '02' ? 'primary' : 'default'"
          @click="selectTime('02')"
          >按季度</el-button
        >
        <el-button
          :type="dateType == '03' ? 'primary' : 'default'"
          @click="selectTime('03')"
          >按年</el-button
        >
        <!-- <el-select
          v-model="xcxStaffId"
          size="small"
          id="select"
          placeholder="请选择区域"
          clearable
          filterable
        >
          <el-option
            v-for="item in partition"
            :key="item.name"
            :value="item.staffId"
            :label="
              item.areaName
                ? `${item.staffName}-${item.areaName}`
                : item.staffName
            "
          >
          </el-option>
        </el-select> -->
        <el-select
          v-model="storeId"
          clearable
          filterable
          size="small"
          id="select"
          placeholder="请选择门店"
        >
          <el-option
            v-for="item in storeList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="searchAll"
          style="margin-left:10px"
          >查询</el-button
        >
      </div>
      <div class="top-card-right fs-12">
        <div style="color:#20A0FF;">每月5号更新上月数据</div>
        <div style="color:#FF4949;margin-top:10px">最近更新：{{lastBillingDate}}</div>
      </div>
    </div>
    <div class="flex flex-jb flex-wrap">
      <Card
        cardTitle="询价量&成交量"
        :entryTime="entryTime"
        :dateType="dateType"
        dataType="01"
        :latelyList="inquiryLatelyList"
        :lately="inquiryLately"
        :dataEchart="inquiryList"
        @addTotalDate="getInquiryDeal"
      ></Card>
      <Card
        cardTitle="回收交易额&用户成交额&回收毛利"
        :entryTime="entryTime"
        :dateType="dateType"
        dataType="02"
        :latelyList="finalLatelyList"
        :lately="finalLately"
        :dataEchart="finalList"
        @addTotalDate="getDealFinalProfit"
      ></Card>
      <Card
        cardTitle="回收单价&成交客单价&单机毛利"
        :entryTime="entryTime"
        :dateType="dateType"
        dataType="03"
        :latelyList="avgLatelyList"
        :lately="avgLately"
        :dataEchart="avglList"
        @addTotalDate="getAvgPrice"
      ></Card>
      <Card
        cardTitle="公司毛利&员工奖励"
        :entryTime="entryTime"
        :dateType="dateType"
        dataType="04"
        :latelyList="rewardLatelyList"
        :lately="rewardLately"
        :dataEchart="rewardlList"
        @addTotalDate="getProfitReward"
      ></Card>
      <Card
        cardTitle="毛利率环比数据"
        :entryTime="entryTime"
        :dateType="dateType"
        dataType="05"
        :latelyList="rateLatelylyList"
        :lately="rateLately"
        :dataEchart="rateList"
        @addTotalDate="getProfitRate"
      ></Card>
      <Card
        cardTitle="成交率、退款、取消率"
        :lastBillingDateStamp="lastBillingDateStamp"
        :entryTime="entryTime"
        :dateType="dateType"
        dataType="06"
        :latelyList="[]"
        :lately="null"
        :dataEchart="monthRate"
        @addTotalDate="getMonthRate"
      ></Card>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import Card from "./component/card.vue";
import { decryptByDES } from '@/utils/3DES'
export default {
  components: {
    Card,
  },
  data() {
    return {
      entryTime: Date.now(), // 入驻时间戳
      HomeDataList: [],
      dateType: "01",
      // xcxStaffId: "",
      storeId: "",
      lastBillingDate: "",
      storeList: [],
      partition: [],
      inquiryLately:{},
      inquiryList: [],
      inquiryLatelyList: [
        {
          text:"询价量",
          unit: "单",
          key: "inquiryNum",
          compare: "thanInquiryNumUp",
          qoq: "thanInquiryNum",
        },
        {
          text:"成交量",
          unit: "单",
          key: "dealNum",
          compare: "thanDealNumUp",
          qoq: "thanDealNum",
        },
        {
          text:"",
          unit: "",
          key: "",
          compare: "",
          qoq: "",
        },
      ],
      finalLately: {},
      finalList: [],
      finalLatelyList: [
        {
          text:"回收交易额",
          unit: "元",
          key: "dealAmount",
          compare: "thanDealAmountUp",
          qoq: "thanDealAmount",
        },
        {
          text:"用户成交额",
          unit: "元",
          key: "finalAmount",
          compare: "thanFinalAmountUp",
          qoq: "thanFinalAmount",
        },
        {
          text:"回收毛利",
          unit: "元",
          key: "dealProfit",
          compare: "thanDealProfitUp",
          qoq: "thanDealProfit",
        },
      ],
      avgLately: {},
      avglList: [],
      avgLatelyList: [
        {
          text:"回收单价",
          unit: "元",
          key: "avgDealPrice",
          compare: "thanAvgDealPriceUp",
          qoq: "thanAvgDealPrice",
        },
        {
          text:"回收客单价",
          unit: "元",
          key: "avgFinalPrice",
          compare: "thanAvgFinalPriceUp",
          qoq: "thanAvgFinalPrice",
        },
        {
          text:"单机毛利",
          unit: "元",
          key: "avgDealProfit",
          compare: "thanAvgDealProfitUp",
          qoq: "thanAvgDealProfit",
        },
      ],
      rewardLately: {},
      rewardlList: [],
      rewardLatelyList: [
        {
          text:"公司毛利",
          unit: "元",
          key: "dealProfit",
          compare: "thanDealProfitUp",
          qoq: "thanDealProfit",
        },
        {
          text:"员工奖励",
          unit: "元",
          key: "staffReward",
          compare: "thanStaffRewardUp",
          qoq: "thanStaffReward",
        },
        {
          text:"",
          unit: "",
          key: "",
          compare: "",
          qoq: "",
        },
      ],
      rateLately: {},
      rateList: [],
      rateLatelylyList: [
        {
          text:"毛利率",
          unit: "%",
          key: "dealProfitRate",
          compare: "thanDealProfitRateUp",
          qoq: "thanDealProfitRate",
        },
        {
          text:"",
          unit: "",
          key: "",
          compare: "",
          qoq: "",
        },
        {
          text:"",
          unit: "",
          key: "",
          compare: "",
          qoq: "",
        },
      ],
      monthRate: {},
      storeData: {}
    };
  },
  created() {
    console.log(this.$route.query.id, 'id');
    this.companyId = decryptByDES(this.$route.query.id,'63e42c2444e94c1ebca21d30d2aa39a5');
    this.companyName = this.$route.query.name;
    this.HomeData();
    this.getEntryTime();
    this.getStoreList();
    this.getCompanyBasic()
    // this.getNotPInspectorSelect();
  },
  methods: {
    // 获取入驻时间戳
    getEntryTime() {
      _api.getEntryTime({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.entryTime = res.data.entryTimeStamp;
          this.lastBillingDate = res.data.lastBillingDate
          this.lastBillingDateStamp = res.data.lastBillingDateStamp
        }
      });
    },
    HomeData() {
      _api.storeHomeData({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.HomeDataList = res.data;
          this.$store.commit("tagsView/POST_HOMEDATALIST", this.HomeDataList);
          console.log(this.HomeDataList);
        }
      });
    },
    getCompanyBasic () {
      _api.getCompanyBasic({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.storeData = res.data;
        }
      });
    },
    // 区域总监下拉列表
    // getNotPInspectorSelect() {
    //   _api.getNotPInspectorSelect({ scope: "00" }).then((res) => {
    //     this.partition = res.data;
    //   });
    // },
    // 门店下拉列表
    getStoreList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    // 询价量、成交量环比数据
    getInquiryDeal({ e, dateType }) {
      this.timeData1 = e;
      let dataParams = {
        storeId: this.storeId,
        companyId: this.companyId,
        // xcxStaffId: this.xcxStaffId,
        dateType: this.dateType,
      };
      if (dateType == "01") {
        let startTime = e[0].split("-");
        let endTime = e[1].split("-");
        dataParams.startYear = startTime[0];
        dataParams.endYear = endTime[0];
        dataParams.startMonth = startTime[1];
        dataParams.endMonth = endTime[1];
      }
      if(dateType == "02"){
        let startTime = e.startQuarter.split("-");
        let endTime = e.endQuarter.split("-");
        if(startTime[0]>endTime[0]){
          dataParams.startYear = endTime[0]
          dataParams.endYear = startTime[0]
          dataParams.startQuarter = endTime[1]
          dataParams.endQuarter = startTime[1]
        }else if(startTime[0] == endTime[0]){
          dataParams.startYear = startTime[0]
          dataParams.endYear = endTime[0]
          if(startTime[1] > endTime[1]){
            dataParams.startQuarter = endTime[1]
            dataParams.endQuarter = startTime[1]
          }else{
            dataParams.startQuarter = startTime[1]
            dataParams.endQuarter = endTime[1]
          }
        }else {
          dataParams.startYear = startTime[0]
          dataParams.startQuarter = startTime[1]
          dataParams.endYear = endTime[0]
          dataParams.endQuarter = endTime[1]
        }
      }
      if(dateType == "03"){
        dataParams.startYear = e.curStartYear > e.curEndYear? e.curEndYear: e.curStartYear
        dataParams.endYear = e.curStartYear > e.curEndYear? e.curStartYear: e.curEndYear
      }
      _api.getInquiryDeal(dataParams).then((res) => {
        if (res.code === 1) {
          this.inquiryLately = res.data.newest;
          this.inquiryList = res.data.list;
        }
      });
    },
    // 回收交易额、用户成交额&公司毛利
    getDealFinalProfit({ e, dateType }) {
      // console.log(e);
      this.timeData2 = e;
      let dataParams = {
        storeId: this.storeId,
        companyId: this.companyId,
        // xcxStaffId: this.xcxStaffId,
        dateType: this.dateType,
      };
      if (dateType == "01") {
        let startTime = e[0].split("-");
        let endTime = e[1].split("-");
        dataParams.startYear = startTime[0];
        dataParams.endYear = endTime[0];
        dataParams.startMonth = startTime[1];
        dataParams.endMonth = endTime[1];
      }
      if(dateType == "02"){
        let startTime = e.startQuarter.split("-");
        let endTime = e.endQuarter.split("-");
        if(startTime[0]>endTime[0]){
          dataParams.startYear = endTime[0]
          dataParams.endYear = startTime[0]
          dataParams.startQuarter = endTime[1]
          dataParams.endQuarter = startTime[1]
        }else if(startTime[0] == endTime[0]){
          dataParams.startYear = startTime[0]
          dataParams.endYear = endTime[0]
          if(startTime[1] > endTime[1]){
            dataParams.startQuarter = endTime[1]
            dataParams.endQuarter = startTime[1]
          }else{
            dataParams.startQuarter = startTime[1]
            dataParams.endQuarter = endTime[1]
          }
        }else {
          dataParams.startYear = startTime[0]
          dataParams.startQuarter = startTime[1]
          dataParams.endYear = endTime[0]
          dataParams.endQuarter = endTime[1]
        }
      }
      if(dateType == "03"){
        dataParams.startYear = e.curStartYear > e.curEndYear? e.curEndYear: e.curStartYear
        dataParams.endYear = e.curStartYear > e.curEndYear? e.curStartYear: e.curEndYear
      }
      _api.getDealFinalProfit(dataParams).then((res) => {
        if (res.code === 1) {
          this.finalLately = res.data.newest;
          this.finalList = res.data.list;
        }
      });
    },
    // 回收单价&成交客单价&单机毛利
    getAvgPrice({ e, dateType }) {
      // console.log(e);
      this.timeData3 = e;
      let dataParams = {
        storeId: this.storeId,
        companyId: this.companyId,
        // xcxStaffId: this.xcxStaffId,
        dateType: this.dateType,
      };
      if (dateType == "01") {
        let startTime = e[0].split("-");
        let endTime = e[1].split("-");
        dataParams.startYear = startTime[0];
        dataParams.endYear = endTime[0];
        dataParams.startMonth = startTime[1];
        dataParams.endMonth = endTime[1];
      }
      if(dateType == "02"){
        let startTime = e.startQuarter.split("-");
        let endTime = e.endQuarter.split("-");
        if(startTime[0]>endTime[0]){
          dataParams.startYear = endTime[0]
          dataParams.endYear = startTime[0]
          dataParams.startQuarter = endTime[1]
          dataParams.endQuarter = startTime[1]
        }else if(startTime[0] == endTime[0]){
          dataParams.startYear = startTime[0]
          dataParams.endYear = endTime[0]
          if(startTime[1] > endTime[1]){
            dataParams.startQuarter = endTime[1]
            dataParams.endQuarter = startTime[1]
          }else{
            dataParams.startQuarter = startTime[1]
            dataParams.endQuarter = endTime[1]
          }
        }else {
          dataParams.startYear = startTime[0]
          dataParams.startQuarter = startTime[1]
          dataParams.endYear = endTime[0]
          dataParams.endQuarter = endTime[1]
        }
      }
      if(dateType == "03"){
        dataParams.startYear = e.curStartYear > e.curEndYear? e.curEndYear: e.curStartYear
        dataParams.endYear = e.curStartYear > e.curEndYear? e.curStartYear: e.curEndYear
      }
      _api.getAvgPrice(dataParams).then((res) => {
        if (res.code === 1) {
          this.avgLately = res.data.newest;
          this.avglList = res.data.list;
        }
      });
    },
    // 公司毛利&员工奖励
    getProfitReward({ e, dateType }) {
      // console.log(e);
      this.timeData4 = e;
      let dataParams = {
        storeId: this.storeId,
        companyId: this.companyId,
        // xcxStaffId: this.xcxStaffId,
        dateType: this.dateType,
      };
      if (dateType == "01") {
        let startTime = e[0].split("-");
        let endTime = e[1].split("-");
        dataParams.startYear = startTime[0];
        dataParams.endYear = endTime[0];
        dataParams.startMonth = startTime[1];
        dataParams.endMonth = endTime[1];
      }
      if(dateType == "02"){
        let startTime = e.startQuarter.split("-");
        let endTime = e.endQuarter.split("-");
        if(startTime[0]>endTime[0]){
          dataParams.startYear = endTime[0]
          dataParams.endYear = startTime[0]
          dataParams.startQuarter = endTime[1]
          dataParams.endQuarter = startTime[1]
        }else if(startTime[0] == endTime[0]){
          dataParams.startYear = startTime[0]
          dataParams.endYear = endTime[0]
          if(startTime[1] > endTime[1]){
            dataParams.startQuarter = endTime[1]
            dataParams.endQuarter = startTime[1]
          }else{
            dataParams.startQuarter = startTime[1]
            dataParams.endQuarter = endTime[1]
          }
        }else {
          dataParams.startYear = startTime[0]
          dataParams.startQuarter = startTime[1]
          dataParams.endYear = endTime[0]
          dataParams.endQuarter = endTime[1]
        }
      }
      if(dateType == "03"){
        dataParams.startYear = e.curStartYear > e.curEndYear? e.curEndYear: e.curStartYear
        dataParams.endYear = e.curStartYear > e.curEndYear? e.curStartYear: e.curEndYear
      }
      _api.getProfitReward(dataParams).then((res) => {
        if (res.code === 1) {
          this.rewardLately = res.data.newest;
          this.rewardlList = res.data.list;
        }
      });
    },
    // 毛利率环比数据
    getProfitRate({ e, dateType }) {
      // console.log(e);
      this.timeData5 = e;
      let dataParams = {
        storeId: this.storeId,
        companyId: this.companyId,
        // xcxStaffId: this.xcxStaffId,
        dateType: this.dateType,
      };
      if (dateType == "01") {
        let startTime = e[0].split("-");
        let endTime = e[1].split("-");
        dataParams.startYear = startTime[0];
        dataParams.endYear = endTime[0];
        dataParams.startMonth = startTime[1];
        dataParams.endMonth = endTime[1];
      }
      if(dateType == "02"){
        let startTime = e.startQuarter.split("-");
        let endTime = e.endQuarter.split("-");
        if(startTime[0]>endTime[0]){
          dataParams.startYear = endTime[0]
          dataParams.endYear = startTime[0]
          dataParams.startQuarter = endTime[1]
          dataParams.endQuarter = startTime[1]
        }else if(startTime[0] == endTime[0]){
          dataParams.startYear = startTime[0]
          dataParams.endYear = endTime[0]
          if(startTime[1] > endTime[1]){
            dataParams.startQuarter = endTime[1]
            dataParams.endQuarter = startTime[1]
          }else{
            dataParams.startQuarter = startTime[1]
            dataParams.endQuarter = endTime[1]
          }
        }else {
          dataParams.startYear = startTime[0]
          dataParams.startQuarter = startTime[1]
          dataParams.endYear = endTime[0]
          dataParams.endQuarter = endTime[1]
        }
      }
      if(dateType == "03"){
        dataParams.startYear = e.curStartYear > e.curEndYear? e.curEndYear: e.curStartYear
        dataParams.endYear = e.curStartYear > e.curEndYear? e.curStartYear: e.curEndYear
      }
      _api.getProfitRate(dataParams).then((res) => {
        if (res.code === 1) {
          this.rateLately = res.data.newest;
          this.rateList = res.data.list;
        }
      });
    },
    // 成交率、退款、取消率
    getMonthRate({ e, dateType }) {
      // console.log(e);
      this.timeData6 = e;
      let dataParams = {
        storeId: this.storeId,
        companyId: this.companyId,
        // xcxStaffId: this.xcxStaffId,
        dateType: this.dateType,
      };
      if (this.dateType == "01") {
        let startTime = e.split("-");
        dataParams.startYear = startTime[0];
        dataParams.startMonth = startTime[1];
      }
      if (dateType == "02") {
        let startTime = e.endQuarter.split("-");
        dataParams.startYear = startTime[0];
        dataParams.startQuarter = startTime[1];
      }
      if (dateType == "03") {
        dataParams.startYear = e.curEndYear;
      }
      _api.getMonthRate(dataParams).then((res) => {
        if (res.code === 1) {
          this.monthRate = res.data;
        }
      });
    },
    selectTime(type) {
      if (type == this.dateType) {
        return;
      }
      this.dateType = type;
    },
    searchAll() {
      this.getInquiryDeal({ e: this.timeData1, dateType: this.dateType });
      this.getDealFinalProfit({ e: this.timeData2, dateType: this.dateType });
      this.getAvgPrice({ e: this.timeData3, dateType: this.dateType });
      this.getProfitReward({ e: this.timeData4, dateType: this.dateType });
      this.getProfitRate({ e: this.timeData5, dateType: this.dateType });
      this.getMonthRate({ e: this.timeData6, dateType: this.dateType });
    },
  },
};
</script>

<style scoped lang="scss">
.top_model {
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  margin-bottom: 10px;
  width: 100%;
  height: 66px;
  background: #e4ecfd;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    margin: 0 2%;
    height: 66px;
    line-height: 66px;
    .store_le {
      div {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: #333333;
      }
      div:last-child {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: rgba(9, 129, 255, 1);
      }
    }
    .poople_le {
      margin-left: 90px;
      div {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: #333333;
      }
      div:last-child {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: rgba(9, 129, 255, 1);
      }
    }
  }
  .right {
    height: 66px;
    line-height: 66px;
    margin-right: 28px;
    font-size: 16px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff687b;
  }
}
.top-card {
  position: relative;
  width: 100%;
  height: 110px;
  background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  padding: 20px 0 0;
  margin-bottom: 15px;
  .title {
    color: #333333;
    font-size: 16px;
    line-height: 18px;
    padding-left: 7px;
    border-left: 4px solid #20a0ff;
    box-sizing: border-box;
    font-weight: bold;
  }
  .btn-box {
    display: flex;
    align-items: center;
    padding: 0 28px;
    margin-top: 17px;
    /deep/.el-button {
      padding: 0;
      width: 80px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /deep/.el-select {
      margin-left: 10px;
    }
    /deep/ #select {
      height: 32px;
      min-height: 32px;
      position: relative;
    }
    /deep/ input::-webkit-input-placeholder {
      color: #a1a4af !important;
    }
    /deep/.el-select,
    /deep/.el-input,
    /deep/.el-input__inner {
      background-color: #f9fbfd;
      border-color: #d9e6ff;
    }
    /deep/.el-select .el-input.is-focus .el-input__inner {
      border-color: #45a3fc;
    }
    //显示时按钮样式
    .el-button--primary {
      //需要更改的按钮类型 type='primary'
      background: #20a0ff !important;
      border-color: #20a0ff !important;
    }
    //移入时按钮样式 type='primary'
    .el-button--primary:hover {
      background: #20a0ff !important;
      border-color: #20a0ff !important;
      color: #fff !important;
      opacity: 0.8;
    }
    .el-button--default {
      //需要更改的按钮类型 type='default'
      background: #f9fbfd !important;
      border-color: #d9e6ff !important;
      color: #8492a6 !important;
    }
    //移入时按钮样式type='default'
    .el-button--default:hover {
      background: #fff !important;
      border-color: #45a3fc !important;
      color: #20a0ff !important;
      opacity: 0.8;
    }
  }
  .top-card-right{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 17px;
    top: 20px;
  }
}

.flex {
  display: flex;
  align-items: center;
}
.flex-jb {
  display: flex;
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.fs-12{
  font-size: 12px;
}
</style>
